<template>
	<div class="outer-wrapper">

		<div id="top-nav">
			<div v-if="tripID" @click="back()">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</div>
			<router-link v-else :to="{ name: 'Trip', params: { trip_id: this.tripID }}">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>

		<div class="inner-wrapper">
			<div class="heading4">Review Your Booking</div>

			<Loader :loading="trip.loading" :error="trip.error"/>

			<div v-if="!trip.loading && !trip.error">
				<span v-for="(item, index) in tripItems" :key="index">
					<!-- <pre>{{ item }}</pre> -->
					<TripItem
						:id="item.id"
						:tripID="trip.tripID"
						:type="item.type"
						:name="item.name"
						:price="item.price"
						:date="item.date"
						:travellers="item.travellers"
						:bookingStatus="item.bookingStatus"
						:alert="item.alerts.all.length > 0 ? item.alerts.all[0] : false"
						:data="item.data"

						:showContinueButton="false"
						loadOpen="true"

						class="item"
					/>
				</span>
			</div>
		</div>
		<div v-if="!trip.loading && !trip.error">
			<TripItemsSummary
				:tripID="trip.tripID"
				:groupedTripItems="{
					'Bookable': {
						items: this.tripItems,
						totalPrice: this.totalPrice,
						heading: 'Booking Summary'
					}
				}"
			/>

			<div class="terms inner-wrapper">
				<input type="checkbox" id="terms" v-model="agreedToTerms">
				<label for="terms">I have read and accept the <router-link :to="{ name: 'Terms and Conditions', query: { redirect: this.$route.path } }">Terms and Conditions</router-link>, the <router-link :to="{ name: 'Licences', query: { redirect: this.$route.path } }">Licences</router-link> and <router-link :to="{ name: 'Privacy Policy', query: { redirect: this.$route.path } }">Privacy Policy</router-link> pages.</label>
				<p v-if="agreedToTermsError && !agreedToTerms" class="error">You must agree to the terms and conditions.</p>
			</div>
			
			<button class="button" @click="goToPayment()" :class="{ disabled: !agreedToTerms }">Confirm &amp; Pay</button>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapState, mapActions, mapGetters } from 'vuex'
import TripItem from "@/components/trips/TripItem.vue";
import router from '@/router';
import TripItemsSummary from "@/components/trips/TripItemsSummary.vue";
import track from "@/helpers/track.js";

export default {
	data() {
		return {
			tripID: this.$route.params.trip_id,
			tripItemID: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null,

			agreedToTerms: false,
			agreedToTermsError: null,

			loading: true,
			error: "",
		}
	},
	components: {
		Loader,
		TripItem,
		TripItemsSummary
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
		...mapGetters({
			tripBookingValidity: 'trip/tripBookingValidity',
			tripItemsCategorized: 'trip/tripItemsCategorized',
		}),
		tripItems() {
			if(!this.trip.loading && !this.trip.error) {
				if(this.tripItemID) {
					// If specific trip item, get specific data
					return [this.trip.tripItems[this.tripItemID]];
				} else {
					// Else get all trip items data
					return this.tripItemsCategorized.bookable.items;
				}
			}
			return [];
			
		},
		totalPrice() {
			if(!this.trip.loading && !this.trip.error) {
				if(this.tripItemID) {
					return this.trip.tripItems[this.tripItemID].price;
				} else {
					return this.tripItemsCategorized.bookable.totalPrice;
				}
			}
			return 0;
		}
	},
	async created () {
		await this.refresh();

		try {
			track.ecommerce("begin_checkout", {
				currency: "GBP",
				value: this.totalPrice,
			}, this.tripItems);
		} catch(e) {
			console.error(e);
			this.$rollbar.error("Tracking: " + e);
		}
	},
	methods: {
		...mapActions({
			initTrip: "trip/initTrip",
			alertSuccess: "alert/success",
			alertError: "alert/error",
		}),
		goToPayment() {
			if(!this.agreedToTerms) {
				this.agreedToTermsError = true;
				return;
			}
			router.push({
				name: "Payment",
				params: {
					trip_id: this.tripID,
					trip_item_id: this.tripItemID
				}
			})
		},
		async refresh() {
			await this.initTrip({ tripID: this.tripID });

			// TODO: Add checking all items are valid, otherwise redirect.

		},
		back() {
			if(this.tripItemID) {
				if(this.trip.tripItems[this.tripItemID].type == "tour") {
					router.push({
						name: "Saved Tour Details",
						params: {
							trip_id: this.tripID,
							trip_item_id: this.tripItemID
						}
					})
				} else if(this.trip.tripItems[this.tripItemID].type == "flight") {
					router.push({
						name: "Saved Flights Details",
						params: {
							trip_id: this.tripID,
							trip_item_id: this.tripItemID
						}
					})
				}
			} else {
				router.push({
					name: "Trip",
					params: {
						trip_id: this.tripID,
					}
				})
			}

		}
	},
}
</script>

<style scoped>
	/* .inner-wrapper {
		padding: 80px 10px 0;
	} */
	.item {
		max-width: 375px;
		margin-bottom: 25px;
	}
	.button {
		margin: 0 auto;
		margin-bottom: 20px;
		display: block;
		margin-top: 30px;
	}

	.heading {
		color: #118AB2;
	}

	.error {
		color: red;
		text-align: center;
	}
</style>