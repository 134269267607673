var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer-wrapper"},[_c('div',{attrs:{"id":"top-nav"}},[(_vm.tripID)?_c('div',{on:{"click":function($event){return _vm.back()}}},[_c('img',{staticClass:"back-icon",attrs:{"src":require("@/assets/back-icon.png"),"alt":"back"}})]):_c('router-link',{attrs:{"to":{ name: 'Trip', params: { trip_id: this.tripID }}}},[_c('img',{staticClass:"back-icon",attrs:{"src":require("@/assets/back-icon.png"),"alt":"back"}})])],1),_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"heading4"},[_vm._v("Review Your Booking")]),_c('Loader',{attrs:{"loading":_vm.trip.loading,"error":_vm.trip.error}}),(!_vm.trip.loading && !_vm.trip.error)?_c('div',_vm._l((_vm.tripItems),function(item,index){return _c('span',{key:index},[_c('TripItem',{staticClass:"item",attrs:{"id":item.id,"tripID":_vm.trip.tripID,"type":item.type,"name":item.name,"price":item.price,"date":item.date,"travellers":item.travellers,"bookingStatus":item.bookingStatus,"alert":item.alerts.all.length > 0 ? item.alerts.all[0] : false,"data":item.data,"showContinueButton":false,"loadOpen":"true"}})],1)}),0):_vm._e()],1),(!_vm.trip.loading && !_vm.trip.error)?_c('div',[_c('TripItemsSummary',{attrs:{"tripID":_vm.trip.tripID,"groupedTripItems":{
				'Bookable': {
					items: this.tripItems,
					totalPrice: this.totalPrice,
					heading: 'Booking Summary'
				}
			}}}),_c('div',{staticClass:"terms inner-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreedToTerms),expression:"agreedToTerms"}],attrs:{"type":"checkbox","id":"terms"},domProps:{"checked":Array.isArray(_vm.agreedToTerms)?_vm._i(_vm.agreedToTerms,null)>-1:(_vm.agreedToTerms)},on:{"change":function($event){var $$a=_vm.agreedToTerms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agreedToTerms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agreedToTerms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agreedToTerms=$$c}}}}),_c('label',{attrs:{"for":"terms"}},[_vm._v("I have read and accept the "),_c('router-link',{attrs:{"to":{ name: 'Terms and Conditions', query: { redirect: this.$route.path } }}},[_vm._v("Terms and Conditions")]),_vm._v(", the "),_c('router-link',{attrs:{"to":{ name: 'Licences', query: { redirect: this.$route.path } }}},[_vm._v("Licences")]),_vm._v(" and "),_c('router-link',{attrs:{"to":{ name: 'Privacy Policy', query: { redirect: this.$route.path } }}},[_vm._v("Privacy Policy")]),_vm._v(" pages.")],1),(_vm.agreedToTermsError && !_vm.agreedToTerms)?_c('p',{staticClass:"error"},[_vm._v("You must agree to the terms and conditions.")]):_vm._e()]),_c('button',{staticClass:"button",class:{ disabled: !_vm.agreedToTerms },on:{"click":function($event){return _vm.goToPayment()}}},[_vm._v("Confirm & Pay")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }